import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { postData } from '../helpers/api-helper';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyDYpw_mlwyv3qOzIlQwKBURca46hbIJpgs",
    authDomain: "advan-gt.firebaseapp.com",
    projectId: "advan-gt",
    storageBucket: "advan-gt.firebasestorage.app",
    messagingSenderId: "495496796425",
    appId: "1:495496796425:web:e9845b5f0cbf7bca49dbd8",
    measurementId: "G-QTHJK4TG4E"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async (user_id = null) => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BDF9-u7hAtgsnMC3IwnBu_zMnDzlQLLICaIaecl2V8G1NrVfesN5_fRZwsbSmioVWOWCoKxU7KNVgeg0S7WQinY` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                var params = {user_id:user_id,token:currentToken}
                if(localStorage.getItem('fcmToken') && user_id && currentToken !== localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);
                    // if(user_id != null)
                    postData(process.env.REACT_APP_API+"/register-fcm",params);
                    // toast('update current token for client: ');
                }else if(!localStorage.getItem('fcmToken') && user_id){
                    localStorage.setItem('fcmToken', currentToken);
                    // if(user_id != null)
                    postData(process.env.REACT_APP_API+"/register-fcm",params);
                    // toast('create current token for client: ');
                }
                return currentToken
            } else {
                // toast('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            // toast('An error occurred while retrieving token. '+err);
        });
};

export const onMessageListener = () =>
new Promise((resolve) => {
    onMessage(messaging, (payload) => {
    resolve(payload);
    });
});

export const initializeAppNotification=async()=>{
    const token = await requestForToken()

    const serial_number = localStorage.getItem('serial_number')
    
    if(serial_number){
        var params = {
            message: {
              token: token,
              notification: {
                title: "Device Already Registered",
                body: "You can log in with the registered email"
              },
            //   webpush: {
            //     fcm_options: {
            //       link: process.env.REACT_APP_WEB_URL+"/register"
            //     }
            //   }
            }
        }
    }else{
        postData(process.env.REACT_APP_API+"/register-fcm",{user_id:null,token});
        var params = {
            message: {
              token: token,
              notification: {
                title: "Register Device",
                body: "Please Register Your device to see details of your device"
              },
            //   webpush: {
            //     fcm_options: {
            //       link: process.env.REACT_APP_WEB_URL+"/register"
            //     }
            //   }
            }
        }
    }
    postData(process.env.REACT_APP_API+"/push_notification_exec",params)
}